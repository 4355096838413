import { Atom, Cmp, Ctrl, Mod } from ":mods";
import { BASE_ROUTES } from "../const";
import { actions, hooks } from "../store";
import { NotFoundWithRedirect } from "../components";
import { Dashboard as DashboardLayout, dashboard_actions } from "../../layouts";
import { StudentView } from "../model";

export function Student() {
  const $: StudentView["$"] = {
    actions: {
      ...actions,
      ...dashboard_actions,
      ...Mod.Account.actions,
      ...Mod.Courses.actions,
    },
    hooks: {
      ...hooks,
    },
    CONST: {
      ACCOUNT: {
        TOKEN_TYPE: Mod.Account.CONST.TOKEN_TYPE,
      },
    },
  };

  return (
    <Atom.Route.Guarded
      base={BASE_ROUTES.student}
      layout={() => (
        <DashboardLayout
          topNav={{
            logoHref: BASE_ROUTES.student,
            base: BASE_ROUTES.student,
            items: [
              //
              Mod.Courses.Nav.TopCourse,
              Ctrl.Nav.Element.Filler,
              Mod.I18n.Nav.TopSelector,
              Ctrl.Nav.Element.Divider,
              Mod.Courses.Nav.TopNotesMessagesQuestions,
              Mod.Account.Nav.TopNotifications,
              Ctrl.Nav.Element.Divider,
              Mod.Account.Nav.TopProfile,
            ],
            events: {
              onLogoClicked: () => Mod.Courses.triggers.removeStageSelected(),
            },
          }}
          sideNav={{
            class: "nav-side",
            base: BASE_ROUTES.student,
            items: [
              Mod.Courses.Nav.SideStudentCourse,
              Mod.Schedule.CONST.ANCHORES.side.student.foundation,
              Mod.Resources.CONST.ANCHORES.side.student.foundation,
              Mod.Showcase.CONST.ANCHORES.side.student.foundation,
              ...Mod.Exhibition.CONST.ANCHORES.student.foundation,
            ],
          }}
        />
      )}
      errorElement={() => (
        <NotFoundWithRedirect
          path={Mod.Courses.CONST.ROUTES.student.all_courses}
          base={BASE_ROUTES.student}
          duration={3000}
        />
      )}
      guard={{
        title: "museum training student",
        steps: [
          Mod.Account.guard.checkUserToken,
          Mod.Account.guard.checkUserEvents,
          Mod.Courses.guard.checkSelectedStage,
        ],
      }}
      events={{
        onGuardSuccess: (trigger, { state, isBaseRoute, routeMatch, location }) => {
          const token_type = $.actions.getTokenType();
          const courses = $.actions.getEventsDetails()?.courses || {};
          if (token_type !== Mod.Account.CONST.TOKEN_TYPE.student) {
            trigger.navigate({ base: BASE_ROUTES.auth });
          } else if (!courses["1"].is_application_submitted) {
            // student but not completed the application -> redriect to fill application
            trigger.navigate({ path: Mod.Signup.CONST.ROUTES.COMMITMENT, base: BASE_ROUTES.signup });
          } else {
            if (state === "first_load") {
              $.actions.pushToast({
                type: "success",
                message: `welcome back ${$.actions.getTokenDetails().first_name}`,
              });
            }
            if (isBaseRoute) {
              const route = Mod.Courses.CONST.ROUTES.side.courses.foundation($.actions.getStageSelected());
              console.log("loading route student :: ", route, " :: ", routeMatch(route), " :: ", location.current);
              if (!routeMatch(route)) {
                trigger.navigate(route);
              }
            } else {
              trigger.loadRoute();
            }
          }
        },
        onGuardFailure: (trigger, { step, stepEquals, error, isBaseRoute, state }) => {
          console.log("from student failed :: ", state, " :: with step -> ", step);
          const courses = $.actions.getEventsDetails()?.courses || {};
          if (stepEquals(Mod.Account.guard.checkUserToken)) {
            console.warn("user has no token ", error);
            trigger.navigate({ base: BASE_ROUTES.auth });
          } else if (!courses["1"].is_application_submitted) {
            // student but not completed the application -> redriect to fill application
            trigger.navigate({ path: Mod.Signup.CONST.ROUTES.COMMITMENT, base: BASE_ROUTES.signup });
          } else if (stepEquals(Mod.Courses.guard.checkSelectedStage)) {
            if (!isBaseRoute) {
              trigger.navigate({ base: BASE_ROUTES.student });
            } else {
              trigger.loadRoute();
            }
          } else {
            console.warn("unhandled route error case in ", error);
          }
        },
        onRouteCleanup({}) {
          $.actions.clearLayer();
        },
      }}
    >
      {/* TODO: define clear structure for layout mounted element/modules such as profile */}
      <Mod.Account.ProfileRoutes $={$} />
      <Mod.Courses.StudentRoutes $={$} />
      <Mod.Schedule.StudentRoutes $={$} />
      <Mod.Resources.StudentRoutes $={$} />
      <Mod.Showcase.StudentRoutes $={$} />
      <Mod.Exhibition.StudentRoutes $={$} />
    </Atom.Route.Guarded>
  );
}
